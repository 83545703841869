import React from 'react'
import {useEffect} from 'react'

import { navigate } from "gatsby"







//gradients
// pink - 'linear-gradient(45deg, #fad0c4 0%, #ffd1ff 100%)' 
// blue - 'linear-gradient(45deg, #fff1eb 0%, #ace0f9 100%)'
// green - 'linear-gradient(60deg, #abecd6 0%, #fbed96 100%)'

const ConfirmNumber = ({location}) => {
  //const { Mixpanel } = useStore()
 

  useEffect(() =>{
    
    //could track different home page variants here
    navigate('https://bender-qr.herokuapp.com/sms/giveaway')
   

    

    //console.log('u3',Mixpanel)
    //Mixpanel?.track('Landing Page View-test-v2')
    
  },[])
  


return(

    <>
        <div style={{height:'100vh',width:'100%',display:'flex',alignItems:'center',justifyContent:'center', textAlign:'center'}}><span>If you don't get automatically redirected in a few seconds click <a href = 'sms:+18444430653&body=Send%20this%20text%20to%20confirm%20your%20phone%20number!%20(ref%3A%20GIVEAWAY)%20I%20agree%20to%20receive%20recurring%20automated%20marketing%20text%20messages%20(e.g.%20cart%20reminders)at%20the%20phone%20number%20provided.%20Consent%20is%20not%20a%20condition%20to%20purchase.%20Msg%20and%20data%20rates%20may%20apply.%20Msg%20frequency%20varies.%0A' >here</a> to confirm your phone number.</span></div>
    </>
  )
}

export default ConfirmNumber